//import Modal from 'react-bootstrap/Modal';

import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { useState } from "react";

function FModal(data) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        block
        className="mb-3"
        color="secondary"
        type="button"
        size="md"
        onClick={handleShow}
      >
        View Top Ads
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Top Ads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img src={data.data.imgfb} alt="img" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FModal;
