import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import addStatCardAction from "../../actions/addStatCardAction";
import ModalExample from "../components/ModalExample";

import { useEffect } from "react";
//import addStatCardAction from "../../actions/addStatCardAction";

import axios from "axios";
import { BASE_URL } from "../../utils/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import delprod from "actions/delprod";
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const Hotselling = (props) => {
  const [isLoading, setLoadingStatus] = React.useState(false);
  const [reLoading, setreLoadingStatus] = React.useState(false);
  const [clickedItems, setClickedItems] = React.useState([]);
  const [MHproducts, getMHproducts] = React.useState([]);

  const [Loadingdel, setLoadingdel] = React.useState(false);

  function scrollToTestDiv() {
    const divElement = document.getElementById("test");
    divElement.scrollIntoView({ behavior: "smooth" });
  }

  const getAllHproducts = () => {
    //it should taken in user. interested items

    //setLoadingStatus(true);

    const postemail = props.authState.user.useremail;

    //console.log('postemail for')

    axios
      .post(`${BASE_URL}/users/myproducts`, { email: postemail }) //Query
      .then((res) => {
        const myproduct = res.data.data.mproduct;

        getMHproducts(myproduct);
      })
      .catch((error) => console.error(`Error:${error}`));

    //setLoadingStatus(false);
  };

  useEffect(() => {
    getAllHproducts();
  }, []);
  useEffect(() => {
    getAllHproducts();
  }, [reLoading]);

  // useEffect(() => {
  //   // This effect runs whenever MHproducts changes
  //   // Perform other side effects or actions here
  //   setTimeout(() => setLoadingdel(false), 5000);
  // }, [MHproducts]);
  const handleItemClick = (index) => {
    setClickedItems((prevClickedItems) => [...prevClickedItems, index]);
  };
  const hotsellingproductlistz = MHproducts.map((item, index) => (
    <tr
      key={index}
      style={clickedItems.includes(index) ? { display: "none" } : {}}
    >
      <th>
        <div className="img-container">
          <img alt="Red dot" src={item.imgsrc} />
        </div>
      </th>
      <td>
        <div style={{ width: "450px" }}>
          <small>{item.name.substring(0, 60)}</small>
        </div>
      </td>

      <td className="text-left">
        <div style={{ width: "200px", whiteSpace: "nowrap" }}>
          <Button
            onClick={() => {
              // setbigChartData(item);

              console.log("listen" + item.product2monitorPriceHistory);
            }}
          >
            <ModalExample data={item} user={props.authState.user.useremail} />
          </Button>
        </div>
      </td>

      {/* <td className="text-left" style={{ margin: "10px" }}>
        <div style={{ width: "100px", whiteSpace: "nowrap" }}>
          <Button
            onClick={() => {
              // setbigChartData(item);

              console.log("listen" + item.product2monitorPriceHistory);
            }}
          >
            {" "}
            Lowest price
          </Button>
        </div>
      </td> */}
      <td className="text-left">
        <div style={{ width: "100px", whiteSpace: "nowrap" }}>
          <Button
            onClick={() => {
              // setbigChartData(item);

              console.log("listen" + item.product2monitorPriceHistory);
            }}
          >
            {" "}
            Dropship Now
          </Button>
        </div>
      </td>

      <td className="td-number">
        <Button
          className="btn-link"
          color="primary"
          data-placement="top"
          id={`tooltip${index}`}
          title=""
          type="button"
          data={item}
          onClick={() => {
            // setLoadingdel(true);
            const email = props.authState.user.useremail;
            const itemname = item.name;

            props.handledellist(email, itemname);
            handleItemClick(index);
          }}
        >
          {Loadingdel && <i className="fa fa-refresh fa-spin" />}

          {!Loadingdel && <i className="tim-icons icon-simple-remove" />}
        </Button>
        <UncontrolledTooltip
          delay={0}
          placement="top"
          target={`tooltip${index}`}
        >
          Remove item
        </UncontrolledTooltip>
      </td>
    </tr>
  ));

  return isLoading ? (
    <h4>
      {" "}
      <i className="fa fa-refresh fa-spin" /> Please wait..loading
    </h4>
  ) : (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h5"></CardTitle>
        </CardHeader>
        <CardBody>
          <div className="numbers">
            <h1>My Dashboard </h1>
            <Button className="btn-round btn-just-icon" color="secondary">
              <Link to="/admin/HotSellingwithfb">
                View List of Hotselling Products
              </Link>
            </Button>
          </div>
        </CardBody>

        <CardBody>
          <div
            className="table-responsive"
            style={{
              overflow: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Table className="table-shopping" sx={{ minWidth: 1500 }}>
              <thead>
                <tr>
                  <th className="text-left">Product Image</th>
                  <th className="text-left">Product name</th>
                  <th className="text-left">Reviews</th>
                  <th className="text-left"></th>
                  <th className="text-left"></th>
                  {/* <th className="text-left">Sales performance</th> */}
                  {/* <th className="text-left">Wholesaleprice</th> */}
                </tr>
              </thead>
              <tbody>{hotsellingproductlistz}</tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handledellist: (email, itemname) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch

      dispatch(delprod(email, itemname));
    },
    addStatCardact: (name) => {
      dispatch(addStatCardAction(name));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Hotselling);
//export default Hotselling;
