import axios from "axios";
import { BASE_URL } from "../utils/config";

const addStatCardAction = (name) => (dispatch) => {
  //alert(name)

  axios
    .get(`${BASE_URL}/products?name=${name}`) //Query
    .then((res) => {
      // Set current user
      dispatch({
        type: "getAllStatCards",
        payload: "true",
        productdata: {
          date: res.data.data.product.date,
          category: res.data.data.product.category,
          name: res.data.data.product.name,
          imgsrc: res.data.data.product.imgsrc,
          img: res.data.data.product.img,
          imgfb: res.data.data.product.imgfb,
          prc: res.data.data.product.prc,
          Jrating: res.data.data.product.Jrating,
          AlibabaSellingPrice: res.data.data.product.AlibabaSellingPrice,
          CostPriceNaira: res.data.data.product.CostPriceNaira,
          JnumberOfReviews: res.data.data.product.JnumberOfReviews,
          InterestedinColloboratingtopurchaseWholesale:
            res.data.data.product.InterestedinColloboratingtopurchaseWholesale,
          CountofInterestedinColloboratingtopurchaseWholesale:
            res.data.data.product
              .CountofInterestedinColloboratingtopurchaseWholesale,
          Onlinesalesdatapermonth:
            res.data.data.product.Onlinesalesdatapermonth,
          Offlinesalesdatapermonth:
            res.data.data.product.Offlinesalesdatapermonth,
          TotalSalesData: res.data.data.product.TotalSalesData,
          AverageEstimatedOrderpermonth:
            res.data.data.product.AverageEstimatedOrderpermonth,
          ProductRanking: res.data.data.product.ProductRanking,
          LocalhighestSellingPrice:
            res.data.data.product.LocalhighestSellingPrice,
          LocallowestSellingPrice:
            res.data.data.product.LocallowestSellingPrice,
          Reviews: res.data.data.product.Reviews,
          AmazonSellingPrice: res.data.data.product.AmazonSellingPrice,
          AliexpressSellingPrice: res.data.data.product.AliexpressSellingPrice,
          EstimatedProfitwithhighestsellingprice:
            res.data.data.product.EstimatedProfitwithhighestsellingprice,
          EstimatedProfitwithlowestsellingprice:
            res.data.data.product.EstimatedProfitwithlowestsellingprice,
          FbAds: res.data.data.product.FbAds,
        },
      });
    })
    .catch((Error) => alert(Error));
};

//}

export default addStatCardAction;
