import axios from "axios";
import { BASE_URL } from "../utils/config";

const addMeAsACollaboratorAction =
  (email, product2addc, quantityOrdered) => (dispatch) => {
    const postemail = email;
    const producttoadd = product2addc;
    const quantityOrderedByUser = quantityOrdered;

    axios
      .post(`${BASE_URL}/products/addcollaborator`, {
        email: postemail,
        product2add: producttoadd,
        productOrderNumber: quantityOrderedByUser,
      })
      .then((res) => {
        // Set current user
        dispatch({
          type: "addMetoCollaborators",
          payload: "true",

          productaddedc: {
            useremail: email,
            productaddedc: {
              name: res.data.data.product.name,
              count:
                res.data.data.product
                  .CountofInterestedinColloboratingtopurchaseWholesale,
              list: res.data.data.product
                .InterestedinColloboratingtopurchaseWholesale,
            },
          },
        });
      })
      .catch((res) => alert(res));
  };

export default addMeAsACollaboratorAction;
