import {combineReducers} from "redux";
import authReducer from "./authReducer";
import statCardReducer from "./statCardReducer";
import { withReduxStateSync } from 'redux-state-sync'


const rootReducer = combineReducers({ //this listens to every state. It listens to everything. 
    authState: authReducer,
    statCardState: statCardReducer
});
export default withReduxStateSync(rootReducer)