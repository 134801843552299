import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { BASE_URL } from "../../utils/config";
import { usePaystackPayment } from "react-paystack";
import { useEffect } from "react";

import { connect } from "react-redux";
import subscribeAction from "../../actions/subscribeAction";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

const Pricing = (props) => {
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("");
  const [status, setStatus] = useState("trial");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const makeSubscription = async (email, status, plan) => {
    const name = email;
    const subscriptionStatus = status;
    const subscriptionPlan = plan;

    await axios
      .post(`${BASE_URL}/subscriptions/addsubscription`, {
        name: name,
        subscriptionstatus: subscriptionStatus,
        subscriptionplan: subscriptionPlan,
      })
      .then((res) => {
        console.log("success");
        setSuccess(true);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    // Navigate on successful payment
    if (success === true) {
      history.push("/admin/HotSellingwithfb");
    } // Replace with the actual route you want to navigate to
  }, [success]);
  const config = {
    reference: new Date().getTime().toString(),
    email: props.authState.user.useremail,
    amount: JSON.stringify(15000.0),
    publicKey: "pk_live_a497490341e14b0742d51cde79bc6342b4f8272f",
  };

  // you can call this function anything
  const onSuccess = async (reference) => {
    // console.log(reference);

    await props.handleSubscribe(email);
    await makeSubscription(email, status, plan);
    setSuccess(true);
    //return
  };

  // you can call this function anything
  const onClose = () => {
    //
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const PaystackHookExample = (props) => {
    const initializePayment = usePaystackPayment(config);
    setEmail(props.email);
    setPlan(props.plan);

    return (
      <div>
        <Button
          color="primary"
          onClick={() => {
            initializePayment(onSuccess, onClose);
          }}
        >
          {" "}
          Subscribe Now
        </Button>
      </div>
    );
  };
  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h1 className="title">Pick the best plan for you</h1>
              <h4 className="description">
                You have Free Unlimited Updates and Premium Support on each
                package.
              </h4>
              <h4 className="description">
                Pay N150 to access any plan for just 15 days
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="8">
              <Card className="card-pricing card-primary">
                <CardBody>
                  <CardTitle tag="h1">Base</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <ListGroup>
                    <ListGroupItem> Base Plan consists of </ListGroupItem>
                    <ListGroupItem> Products Explorer</ListGroupItem>
                    <ListGroupItem> Google Chrome Extension </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      Track pricing of 1 - 5 products{" "}
                    </ListGroupItem>
                    <ListGroupItem> 24 X 7 Support</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <p className="text-on-front">
                      <span>N</span>
                      5,000
                    </p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <PaystackHookExample
                    plan={"PLN_8ixobp8mgse0nwy"}
                    email={props.authState.user.useremail}
                  />
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="8">
              <Card className="card-pricing card-warning card-white">
                <CardBody>
                  <CardTitle tag="h1">Mid</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-warning.png").default}
                  />
                  <ListGroup>
                    <ListGroupItem> Business Plan consists of </ListGroupItem>
                    <ListGroupItem> Products Explorer</ListGroupItem>
                    <ListGroupItem> Google Chrome Extension </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      Track pricing of 5 - 10 products{" "}
                    </ListGroupItem>
                    <ListGroupItem> 24 X 7 Support</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <p className="text-on-front">
                      <span>N</span>
                      10,000
                    </p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <PaystackHookExample
                    plan={"PLN_98yn0fnvpf9a08h"}
                    email={props.authState.user.useremail}
                  />
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="8">
              <Card className="card-pricing card-danger card-white">
                <CardBody>
                  <CardTitle tag="h1">Pro</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-danger.png").default}
                  />
                  <ListGroup>
                    <ListGroupItem> Enterprise Plan consists of </ListGroupItem>
                    <ListGroupItem> Products Explorer</ListGroupItem>
                    <ListGroupItem> Google Chrome Extension </ListGroupItem>
                    <ListGroupItem>
                      {" "}
                      Track pricing of 15 - 20 products{" "}
                    </ListGroupItem>
                    <ListGroupItem> 24 X 7 Support</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <p className="text-on-front">
                      <span>N</span>
                      20,000
                    </p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <PaystackHookExample
                    plan={"PLN_du314xjpyl49qny"}
                    email={props.authState.user.useremail}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Col className="mt-5" md="12">
            <h3 className="title">Some Benefits of Subscribing</h3>
          </Col>
          <Row>
            <Col md="4">
              <p>
                Boost your product sales with the right pricing strategy
                <br></br>
                Use our pricing intelligence to give your business an edge
                <br></br>
                Reduce Product tracking time and focus on fulfilling your orders{" "}
                <br></br>
              </p>
            </Col>
            <Col className="ml-auto" md="6">
              <div className="progress-container progress-warning">
                <span className="progress-badge">
                  Increase your productivity
                </span>
                <Progress max="100" value="75" />
              </div>
              <div className="progress-container progress-primary">
                <span className="progress-badge">Optimize your business</span>
                <Progress max="100" value="90" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubscribe: (email) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch
      dispatch(subscribeAction(email));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
