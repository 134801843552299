import axios from 'axios'
import { BASE_URL } from '../utils/config';


const loginAction = (
    email,
    password
) => dispatch =>{

    //alert(password)
    const postData = {
        email,
        password
    };

     axios.post(`${BASE_URL}/users/login`, postData)
     .then(res=>{
        // Set current user
        dispatch({
          type:"login",
          payload:"true",
          userinfo:{
            username: res.data.data,
            useremail: res.data.email,
            subscribestate : res.data.subscribed,
            dateOfRegistration: res.data.date,
            stat : false
          },
         // username: res.data,
         // subscribestate:res.subscribed

         /*
           dispatch({
        type:"register",
        payload:"true",
        userinfo:{
            username: post2Data.name,
            useremail: post2Data.email
          }
    });

         */
        });
    
     })
     .catch(res => alert("Wrong password")
     
     
     )
    }



     
    

export default loginAction;


// Login - get user token
