/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim<Input defaultValue="michael23" type="text" />
<PaystackHookExample />
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import changePasswordAction from "../../actions/ChangePassword";
import axios from "axios";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { BASE_URL } from "../../utils/config";
import { connect } from "react-redux";

const User = (props) => {
  const [state, setState] = React.useState({
    firstname: "",
    lastemail: "",
    username: "",
    email: "",
    password: "",
    newPassword: "",
    newPassword2: "",
  });

  const cancelSubscription = (email) => {
    const name = email;
    const subscriptionStatus = "cancelled";

    //axios.post(`${BASE_URL}/users/addsubscription`, {email: name})
    //.then(res=>{
    //console.log(res)
    //})
    ///.catch(error => console.log(error));

    console.log("Cancel email" + email);
    axios
      .post(`${BASE_URL}/subscriptions/cancelsubscription`, {
        name: name,
        subscriptionstatus: subscriptionStatus,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="content">
        <h5 className="title">Overview</h5>
        <h2 className="title">Account Settings</h2>
        <Row>
          <Col md="7">
            <Card>
              <CardHeader>
                <h1 className="title">General</h1>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="1">
                      <Label> Name</Label>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Input
                          defaultValue={props.authState.user.username}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="1">
                      <Label> Email</Label>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Input
                          placeholder={props.authState.user.useremail}
                          type="email"
                          onFocus={(e) =>
                            setState({ ...state, passFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, passFocus: false })
                          }
                          onChange={(e) =>
                            setState({ ...state, email: e.target.value })
                          }
                          value={state.email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Card>
                      <CardHeader>
                        <h1 className="title">Manage Subscription</h1>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <Button
                            className="btn-fill"
                            color="primary"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              //props.handlepsschange(
                              //state.email,
                              //state.password,
                              //</div>state.newPassword
                              //)
                              cancelSubscription(
                                props.authState.user.useremail
                              );
                              alert("Subscription cancelled");
                            }}
                          >
                            Cancel Subscription
                          </Button>
                        </div>
                      </CardBody>
                      <CardFooter></CardFooter>
                    </Card>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
          <Col md="5">
            <Card className="card-user">
              <CardHeader>
                <h1 className="title">Security</h1>
                <h2 className="title">Change Your Password</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <label> Email address</label>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        placeholder="Input your email"
                        type="email"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                        value={state.email}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <label>Old Password </label>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        placeholder="Old password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, password: e.target.value })
                        }
                        value={state.password}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <label>New Password</label>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        placeholder="NewPassword"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, newPassword: e.target.value })
                        }
                        value={state.newPassword}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <label>Input Password</label>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Input
                        placeholder="Confirm your New Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, newPassword2: e.target.value })
                        }
                        value={state.newPassword2}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <CardText />

                <div className="card-description">
                  Password requirements To create a new password, you have to
                  meet all of the following requirements: Minimum 8 characters
                  At least one special character At least one number Can’t be
                  the same as a previous password
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      props.handlepsschange(
                        state.email,
                        state.password,
                        state.newPassword
                      );
                    }}
                  >
                    Update Password
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

//export default User;

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handlepsschange: (email, password, newPassword) => {
      dispatch(changePasswordAction(email, password, newPassword));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
