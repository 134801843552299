import axios from 'axios'
import { BASE_URL } from '../utils/config';


const subscribeAction = (
    email
) => dispatch =>{

    //alert(password)
    const postData = {
        email
    };
    
     axios.post(`${BASE_URL}/users/addsubscription`, postData)
     .then(res=>{
   
        // Set current user
        dispatch({
          type:"subscribe",
          payload:"true",
          userinfo:{
            username: res.data.data.user.name,
            useremail: res.data.data.user.email,
            subscribestate: res.data.data.user.subscribed,
            dateOfRegistration: res.data.data.user.date,
            stat : false
          }
        });
       
    /*
    userinfo:{
            username: res.data.data,
            useremail: res.data.email,
            subscribestate : res.data.subscribed,
            dateOfRegistration: res.data.date,
            stat : false
          },
    */
     })
     .catch(Error => console.log(Error))
    }



     
    

export default subscribeAction;


// Login - get user token
