

export default (
    state = {},
    action) => {
    switch (action.type){
        case "register":
            console.log("register is", action.payload);
            return{
                ...state,
                loggedIn:
                    action.payload,
                user:action.userinfo
               
            };
        case"login":
            console.log("login is",action.payload);
            console.log(state.name);
            return{
                ...state,
                loggedIn:
                  action.payload,
                user:action.userinfo
               
                
            };
            case"passwchange":
            console.log("passwchange is",action.payload);
            
            return{
               
            };

            case"addproduct":
            console.log("product added",action.payload);
           // console.log(state.name);
            return{
                ...state,
                productadded:
                  action.payload,
                amyproductlist:action.userinfo.userproductlist,
                user:action.userinfo

            };
            
            case"delproduct":
            console.log("product del",action.payload);
           // console.log(state.name);
            return{
                ...state,
                productadded:action.payload,
                dmyproductlist:action.iproductlist.userproductlist,
                delstate:false

            };
            case"delmonitorproduct":
            console.log("delmonitorproduct",action.payload);
           // console.log(state.name);
            return{
                ...state,
                productadded:action.payload,
                dmyproductlist:action.iproductlist.userproductlist,
                delstate:false

            };
            case"monitoredproductstat":
            console.log("monitoredproductstat",action.payload);
           // console.log(state.name);
            return{
                ...state,
                monitoredproduct:action.payload,
                monitoredproductstat:action.productstat.monitoredproductstat,
               

            };
            case"subscribe":
            console.log("subscribe is",action.payload);
            //console.log(state.name);
            return{
                ...state,
                loggedIn:
                  action.payload,
                user:action.userinfo
                

            };
            default:
                return state;
    }
};
//export const selectuser = (state) => state.user.action.userinfo.username.name;