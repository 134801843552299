import{
    createStore,
    applyMiddleware,
    compose
} from "redux";

import reduxThunk from "redux-thunk";



import rootReducer from "./reducers/rootReducer";


function saveToLocalStorage(state) {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
}

function loadFromLocalStorage() {
const serializedState = localStorage.getItem('state');
if (serializedState === null) return undefined;
   return JSON.parse(serializedState);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const presistedState = loadFromLocalStorage();

    
    const store = createStore(
        rootReducer,
        presistedState,
        composeEnhancers(
            applyMiddleware(reduxThunk),
            window.devToolsExtension ? window.devToolsExtension() : f => f
          )
    );

store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;