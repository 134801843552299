import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import classNames from "classnames";

import addMeAsACollaboratorAction from "../../actions/addMeAsACollaboratorAction";
import { Line, Doughnut } from "react-chartjs-2";

import FModal from "../components/FModal";
// react plugin for creating vector maps

import { connect } from "react-redux";

const Productpage = (props) => {
  const [bigChartData, setbigChartData] = React.useState("data2");
  const [state, setState] = React.useState({
    selectedproduct: {
      date: "0",
      category: "0",
      name: "0",
      imgsrc:
        "https://res.cloudinary.com/salesngine/image/upload/v1628249806/unavailable_yvryk3.png",
      imgfb:
        "https://res.cloudinary.com/salesngine/image/upload/v1628249806/unavailable_yvryk3.png",
      prc: "0",
      Jrating: "0",
      JnumberOfReviews: "0",
      Onlinesalesdatapermonth: [],
      Offlinesalesdatapermonth: [],
      TotalSalesData: [],
      AverageEstimatedOrderpermonth: null,
      ProductRanking: null,
      Reviews: [],
      LocalhighestSellingPrice: "0",
      LocallowestSellingPrice: "0",
      AmazonSellingPrice: "0",
      AliexpressSellingPrice: "0",
      AlibabaSellingPrice: "0",
      EstimatedProfitwithhighestsellingprice: "0",
      EstimatedProfitwithlowestsellingprice: "0",
      InterestedinColloboratingtopurchaseWholesale: [],
      CountofInterestedinColloboratingtopurchaseWholesale: 0,
      CostPriceNaira: "0",
      FbAds:
        "https://res.cloudinary.com/salesngine/image/upload/v1628249806/unavailable_yvryk3.png",
    },
  });
  //setState({selectedproduct: {...props.statCardState.selectedproduct}});

  React.useEffect(() => {
    props.statCardState.selectedproduct &&
      setState({ selectedproduct: { ...props.statCardState.selectedproduct } });
  }, [props]);

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  let chart_1_2_3_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };
  //props.statCardState.productaddedc.collaboratorscount

  //Need to work on this chunk of code.. too much and i am sure inefficient

  let fractionTotalOrder = 0;
  let NfractionTotalOrder = 0;
  let numberofCollaborators =
    state.selectedproduct.InterestedinColloboratingtopurchaseWholesale.length;
  const dataTotalOrder = (p) => {
    fractionTotalOrder = (p / 1000) * 100;
    return fractionTotalOrder;
  };

  const NdataTotalOrder = (p) => {
    NfractionTotalOrder = 100 - (p / 1000) * 100;
    return NfractionTotalOrder;
  };
  //let groupBuyOrder = totalOrder(state.selectedproduct.InterestedinColloboratingtopurchaseWholesale)
  // #########################################
  // // // used inside src/views/Dashboard.js
  // #########################################
  const statex = {
    labels: ["Total orders in GroupBuy"],
    datasets: [
      {
        label: "Total orders in GroupBuy",
        backgroundColor: ["rgb(54, 162, 235)"],
        //hoverBackgroundColor: [

        //],
        hoverOffset: 4,
        data: [
          dataTotalOrder(
            state.selectedproduct
              .CountofInterestedinColloboratingtopurchaseWholesale
          ),
          NdataTotalOrder(
            state.selectedproduct
              .CountofInterestedinColloboratingtopurchaseWholesale
          ),
        ],
      },
    ],
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            //backgroundColor: gradientStroke,
            backgroundColor: "fffffff",
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: state.selectedproduct.Onlinesalesdatapermonth,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: state.selectedproduct.Offlinesalesdatapermonth,
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: [
          "Jumai Best Store",
          "Jiji Best Store",
          "Konga Best Store",
          "Shopify Best Store",
          "Facebook Best Store",
        ],
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: state.selectedproduct.Offlinesalesdatapermonth,
          },
        ],
      };
    },
    options: chart_1_2_3_options,
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12" xs="12"></Col>
          <Col lg="12" md="12" xs="12">
            <Row>
              <Col lg="9" md="6" xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Col className="text-left" sm="6">
                      <Button
                        color="secondary"
                        className="mb-3"
                        href="https://chrome.google.com/webstore/detail/salesngine-chrome/nhaeliobpoigmplbedpaanefipaohjoo"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Click to Download Chrome Extension{" "}
                      </Button>
                      <CardTitle tag="h4">
                        2022 Purchases Analytics (Only Available to Paid
                        Members)
                      </CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          color="info"
                          id="0"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: bigChartData === "data1",
                          })}
                          onClick={() => setBgChartData("data1")}
                        >
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Sales Performance Tracking
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>

                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: bigChartData === "data3",
                          })}
                          onClick={() => setBgChartData("data3")}
                        >
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Compare Pricing daily
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area" id="test">
                      <Line
                        data={chartExample1[bigChartData]}
                        options={chartExample1.options}
                      />
                    </div>
                  </CardBody>
                  <small>Data source: Top Nigerian Online Store </small>
                </Card>
              </Col>
              <Col lg="3" md="6" xs="12">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="photo">
                          <img
                            src={state.selectedproduct.imgsrc}
                            alt="product"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="numbers">
                          <p className="card-category">
                            Category = {state.selectedproduct.category}
                          </p>
                          <p className="card-category">
                            Product Name = {state.selectedproduct.name}
                          </p>
                          <p className="card-category">
                            {" "}
                            Product Ranking ={" "}
                            <big>{state.selectedproduct.ProductRanking}</big>
                          </p>
                          <p className="card-category">
                            Local Highest selling price = N
                            {state.selectedproduct.LocalhighestSellingPrice}
                          </p>
                          <p className="card-category">
                            {" "}
                            Local Lowest selling price ={" "}
                            <big>
                              N{state.selectedproduct.LocallowestSellingPrice}
                            </big>
                          </p>

                          <p className="card-category">
                            {" "}
                            AliBaba BestPrice ={" "}
                            {state.selectedproduct.AlibabaSellingPrice}
                          </p>
                          <p className="card-category">
                            Estimated Profit with HSP = N
                            {
                              state.selectedproduct
                                .EstimatedProfitwithhighestsellingprice
                            }
                          </p>
                          <p className="card-category">
                            Estimated Profit with LSP = N
                            {
                              state.selectedproduct
                                .EstimatedProfitwithlowestsellingprice
                            }
                          </p>
                        </div>

                        <div>
                          <FModal data={state.selectedproduct} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="4" md="8">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-success">
                      <i className="tim-icons icon-single-02" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        {" "}
                        Product Cost excluding shipping
                      </p>
                      <CardTitle tag="h3">
                        N{state.selectedproduct.CostPriceNaira}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-danger">
                      <i className="tim-icons icon-molecule-40" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Local Selling price</p>
                      <CardTitle tag="h3">
                        N{state.selectedproduct.LocallowestSellingPrice} - N
                        {state.selectedproduct.LocalhighestSellingPrice}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-warning">
                      <i className="tim-icons icon-shape-star" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Expected Profit Margin </p>
                      <CardTitle tag="h3">
                        {" "}
                        N
                        {
                          state.selectedproduct
                            .EstimatedProfitwithlowestsellingprice
                        }{" "}
                        - N
                        {
                          state.selectedproduct
                            .EstimatedProfitwithhighestsellingprice
                        }
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="8">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-success">
                      <i className="tim-icons icon-sound-wave" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">AliBaba Selling price</p>
                      <CardTitle tag="h3">
                        {state.selectedproduct.AlibabaSellingPrice}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-danger">
                      <i className="tim-icons icon-molecule-40" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        Number of people in GroupBuy
                      </p>
                      <CardTitle tag="h3">
                        {numberofCollaborators
                          ? numberofCollaborators
                          : "Loading.."}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-warning">
                      <i className="tim-icons icon-watch-time" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">GroupBuy Deadline </p>
                      <CardTitle tag="h3">April 26 2022</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats"></div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="8">
            <Card className="card-stats">
              <CardBody>
                <div className="numbers">
                  <p className="card-category"> </p>
                  <p className="card-category">
                    {" "}
                    <br></br>Wholesale price GroupBuy Orders
                  </p>
                  <CardTitle tag="h3">
                    {" " +
                      state.selectedproduct
                        .CountofInterestedinColloboratingtopurchaseWholesale +
                      " preorders made out of 1000 orders needed to purchase at wholesale price"}
                  </CardTitle>
                </div>
                <Doughnut
                  data={statex}
                  options={{
                    title: {
                      display: true,

                      fontSize: 20,
                    },
                    legend: {
                      display: false,
                      position: "right",
                    },
                  }}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <p className="card-category">
                    {" "}
                    <small> GroupBuy </small>
                  </p>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="8"></Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addMeAsACollaborator: (email, product2addc, quantityOrdered) => {
      //product2addc means product a collaborator wants to join to order at whole sale.
      dispatch(
        addMeAsACollaboratorAction(email, product2addc, quantityOrdered)
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Productpage);
