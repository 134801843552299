import axios from 'axios'
import { BASE_URL } from '../utils/config';


const changePasswordAction = (
    email,
    password,
    newPassword

) => dispatch =>{

    //alert(password)
    const postData3 = {
        email,
        password,
        newPassword
    };
    

     axios.post(`${BASE_URL}/users/passwordchange`, postData3)
     .then(res=>{
        // Set current user
        dispatch({
          type:"passwchange",
          payload:"true",
        });
    
     })
     .catch(res => alert(res))
    }



     
    

export default changePasswordAction;

