import { BASE_URL } from "../../utils/config";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function OrderModal(props) {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    nameOfProduct: props.name,
    receiverAddy: "",
    receiverName: "",
    receiverPhone: "",
    senderName: "",
    size: "",
    no: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOrder = async () => {
    // Check if all fields are filled in
    for (const key in formData) {
      if (formData[key] === "") {
        alert(`Please fill in ${key.replace(/([A-Z])/g, " $1").toLowerCase()}`);
        return; // Exit function if any field is empty
      }
    }
    setShow(false);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/orders/addorder`,
        formData
      );
      // console.log("Success:", response.data);
      alert("success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleShow}
        style={{ margin: "70px" }}
      >
        Get Quote
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get Shipping Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nameOfProduct">
              <Form.Control
                type="text"
                placeholder="Order URL"
                name="nameOfProsduct"
                value={formData.nameOfProduct}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="receiverAddy">
              <Form.Control
                type="text"
                placeholder="Receiver Address"
                name="receiverAddy"
                value={formData.receiverAddy}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="receiverName">
              <Form.Control
                type="text"
                placeholder="Receiver Name"
                name="receiverName"
                value={formData.receiverName}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="receiverPhone">
              <Form.Control
                type="text"
                placeholder="Receiver Phone No"
                name="receiverPhone"
                value={formData.receiverPhone}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="senderName">
              <Form.Control
                type="text"
                placeholder="Sender Name"
                name="senderName"
                value={formData.senderName}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="no">
              <Form.Control
                type="text"
                placeholder="no of items"
                name="no"
                value={formData.no}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
            <Form.Group controlId="size">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description (Quantity and Color)"
                name="size"
                value={formData.size}
                onChange={handleChange}
                style={{ color: "black" }} // Set text color to black
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOrder}>
            Place Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderModal;
