/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim<Input defaultValue="michael23" type="text" />
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import axios from "axios";

import { BASE_URL } from "../../utils/config";

// reactstrap components
import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

const Reset = (props) => {
  const [state, setState] = React.useState({
    email: "",
  });

  const confirmemail = (existingemail) => {
    axios
      .post(`${BASE_URL}/users/reset`, { email: existingemail })
      .then((res) => {
        // Set current user

        alert(JSON.stringify(res.data.email));
      })
      .catch((error) => console.error(`Error:${error}`));
  };

  return (
    <>
      <div className="content">
        <CardHeader>
          <h2 className="title">Confirm Your registered email address</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="2">
              <label> Email address</label>
            </Col>
            <Col md="6">
              <FormGroup>
                <Input
                  placeholder="Input your email"
                  type="email"
                  onFocus={(e) => setState({ ...state, passFocus: true })}
                  onBlur={(e) => setState({ ...state, passFocus: false })}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  value={state.email}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="button-container">
            <Button
              className="btn-fill"
              color="primary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();

                const userRegisteredEmail = state.email;
                confirmemail(userRegisteredEmail);
              }}
            >
              Confirm email address
            </Button>
          </div>
        </CardFooter>
      </div>
    </>
  );
};

//export default User;

export default Reset;
