import axios from 'axios'

import { BASE_URL } from '../utils/config';


const monitordelprod = (
    
    email,
    itemname
) => dispatch =>{

    //alert(password)
    
        
       const postemail=  email
        const trackedproduct2del = itemname
        
    
    axios.post(`${BASE_URL}/users/deletetrackedproduct`, {email: postemail,trackedproduct2del:trackedproduct2del })
     .then(res=>{
        // Set current user
        dispatch({
          type:"delmonitorproduct",
          payload:"true",
          iproductlist:{
            useremail: email,
            userproductlist : res.monitorproduct
          }
        });
        //alert("Succesfully deleted from your dashboard");
     })
     .catch(res => alert(res))
    }




export default monitordelprod;


// Login - get user token
