export default (state = {}, action) => {
  switch (action.type) {
    case "getAllStatCards":
      console.log("ALL STATS=====>", action.productdata);

      return {
        ...state,
        productdata: action.productdata,
      };

    case "addMetoCollaborators":
      console.log(
        "COLLABORATING ON THE PRODUCTS BELOW=====>",
        action.productaddedc
      );

      return {
        ...state,
        productaddedc: {
          username: action.productaddedc.useremail,
          productname: action.productaddedc.productaddedc.name,
          collaboratorscount: action.productaddedc.productaddedc.count,
          list: action.productaddedc.productaddedc.list,
        },
      };

    case "updateProductPage":
      console.log("Productpage=====>", action.selectedproduct);

      return {
        ...state,
        selectedproduct: action.selectedproduct,
      };

    default:
      return state;
  }
};
//statCardReducer listens for all this action.type.
