import React from "react";

import { connect } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import add2list from "actions/add2list";
import Paginationer from "views/components/Paginationer";

const HotsellingwithFB = (props) => {
  const [state, setState] = React.useState({
    cardusernameemail: "",
    productname: "",
  });
  React.useEffect(() => {
    props.authState.user.username &&
      setState({ cardusernameemail: { ...props.authState.user.useremail } });
  }, [props]);
  // if (props.authState.user.subscribestate === false) {
  //   return <Redirect to="/admin/Pricing" />;
  // } else {
  return (
    <>
      <div className="content" style={{ marginTop: "50px" }}>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5"></CardTitle>
              </CardHeader>
              <CardBody>
                <div className="htheading">
                  <h3>
                    {" "}
                    Discover Winning and trendy products to sell online daily
                  </h3>

                  <Button
                    color="secondary"
                    className="mb-3"
                    href="https://chrome.google.com/webstore/detail/salesngine-chrome/nhaeliobpoigmplbedpaanefipaohjoo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Click to Download Chrome Extension{" "}
                  </Button>
                  <h4>
                    {" "}
                    Click button above to download chrome extension for
                    automatic price drop alerts
                  </h4>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h5"></CardTitle>
              </CardHeader>
              <CardBody>
                <div className="htheading">
                  <Paginationer
                    props={props}
                    emailx={props.authState.user.useremail}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
//};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleadd2list: (email, product2add) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch
      dispatch(add2list(email, product2add));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HotsellingwithFB);

//export default HotsellingwithFB;
