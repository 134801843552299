import axios from "axios";

import { BASE_URL } from "../utils/config";

const addmonitoredprod = (email, itemname) => (dispatch) => {
  //alert(password)

  const postemail = email;
  const product = itemname;

  axios
    .post(`${BASE_URL}/users/findproduct`, {
      email: postemail,
      product: product,
    })
    .then((res) => {
      // Set current user
      dispatch({
        type: "monitoredproductstat",
        payload: "true",
        productstat: {
          useremail: email,
          monitoredproductstat: res.data.data.result,
        },
      });
      //alert("Succesfully deleted from your dashboard");
    })
    .catch((res) => alert(res));
};

export default addmonitoredprod;

// Login - get user token
