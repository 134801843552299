import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title:
      "Our Tools Help Sellers Increase Profit Margin and Number of Orders Per Month",
    paragraph:
      "Our tools provide market intelligence to entrepreneurs, sellers and dropshippers  and helps them to discover good products to sell online. We also help them discover the cheapest rate possible for this products inorder to increase profit margins",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-02.svg")
                          .default
                      }
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Multi-Platform Price Tracking</h4>
                  <p className="m-0 text-sm">
                    Track the price of products across multiple stores on one
                    dashboard. This helps you to always know the best price to
                    set at every given moment. Placing you ahead of your
                    competitors at every given moment.
                  </p>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-01.svg")
                          .default
                      }
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Find and Monitor Hotselling products online
                  </h4>
                  <p className="m-0 text-sm">
                    Our tools provide you with list of products that are
                    performing Online. This helps to save sellers from selling
                    products that perform poorly online and consequently this
                    feature helps to increase your sales by month.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-03.svg")
                          .default
                      }
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Purchase products locally at cheapest price possible
                  </h4>
                  <p className="m-0 text-sm">
                    The tool provides you with the cheapest selling price for
                    your product. Dropshippers take advantage of this feature to
                    get the best deal for a product . It is also used to
                    determine best selling price
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-04.svg")
                          .default
                      }
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Marketing Insights on how to best sell your products
                  </h4>
                  <p className="m-0 text-sm">
                    The tool provides with information on how best to sell each
                    product online interms of succesful headlines and copies.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-05.svg")
                          .default
                      }
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Estimate sales per month using our tools
                  </h4>
                  <p className="m-0 text-sm">
                    We provide you with key details on each product such as
                    Average estimated sales per month, Local Highestselling
                    price, sales trend for each product, product reviews,
                    Estimated Profits per product.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={
                        require("./../../assets/images/feature-tile-icon-06.svg")
                          .default
                      }
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Find New Product Ideas to Bring to the Market.
                  </h4>
                  <p className="m-0 text-sm">
                    We provide you with new product ideas. You also get stats on
                    their performance online. This will help you to make clear
                    data driven decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
