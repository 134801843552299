/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

import Dashboard from "views/Dashboard.js";

import Pricing from "views/pages/Pricing.js";

import User from "views/pages/User.js";

import HotsellingwithFB from "views/forms/HtsellingwithFb";
//import Footer from '..landingpage/components/layout/Footer';

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterNav from "views/components/FooterNav.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import logo from "assets/img/saleslogo.png";

var ps;

const Admin = (props) => {
  const [activeColor, setActiveColor] = React.useState("blue");

  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        //console.log(prop.authState.loggedIn);

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const handleMiniClick = () => {
    //let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      //setSidebarMini(false);
      //notifyMessage += "deactivated...";
    } else {
      // setSidebarMini(true);
      // notifyMessage += "activated...";
    }

    //notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  if (!props.authState.loggedIn) {
    return <Redirect to="/auth/login" />;
  } else {
    //if(props.authState.subscribe === false){
    //console.log(props.authState.user.subscribestate)
    //return <Redirect to= "/admin/Pricing" />
    //}
    //else{

    //}
    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...props}
          routes={[
            {
              path: "/HotSellingwithfb",
              name: "Explorer",
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-notes",
              component: HotsellingwithFB,
              layout: "/admin",
            },
            {
              path: "/dashboard",
              name: "Dashboard",
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-chart-pie-36",
              component: Dashboard,
              layout: "/admin",
            },

            {
              path: "/user-profile",
              name: "User Profile",
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-image-02",
              component: User,
              layout: "/admin",
            },
            {
              path: "/Pricing",
              name: "Pricing",
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-image-02",
              component: Pricing,
              layout: "/admin",
            },
          ]}
          activeColor={activeColor}
          logo={{
            outterLink: "https://www.creative-tim.com/",
            text: "SALESNGINE",
            imgSrc: logo,
          }}
          closeSidebar={closeSidebar}
          style={{ marginTop: "500px" }}
        />
        <div className="main-panel" ref={mainPanelRef} data={activeColor}>
          <AdminNavbar
            {...props}
            handleMiniClick={handleMiniClick}
            brandText={getActiveRoute(routes)}
            sidebarOpened={sidebarOpened}
            toggleSidebar={toggleSidebar}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
              <FooterNav fluid />
            )
          }
        </div>
      </div>
    );
  }
};
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps, {})(Admin);
