import React from "react";
import classNames from "classnames";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <div>
      <nav {...props} className={classes}>
        <ul className="list-reset">
          <li>
            <a href="https://salesngine.com/contact/">Contact Us</a>
          </li>
          <li>
            <a href="https://salesngine.com/about-us/">About Us</a>
          </li>
          <li>
            <a href="https://salesngine.com/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a href="https://salesngine.com/contact/">Support</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default FooterNav;
