import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import addmonitoredprod from "../../actions/addmonitoredproduct";

import OrderModal from "../../views/components/OrderModal";

import { useEffect } from "react";
//import addStatCardAction from "../../actions/addStatCardAction";

import axios from "axios";
import Graph from "./Graph";
import { BASE_URL } from "../../utils/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  Table,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import monitordelprod from "../../actions/monitordelprod";
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const Monitorproduct = (props) => {
  const [isLoading, setLoadingStatus] = React.useState(false);
  const [bigChartData, setbigChartData] = React.useState([]);
  const [MHproducts, getMHproducts] = React.useState([]);
  const [clickItems, setClickedItems] = React.useState([]);

  const [Loadingdel, setLoadingdel] = React.useState(false);

  function savings(a, b) {
    //console.log(a, b);
    if (typeof a === "number" && typeof b === "number") {
      let result = b - a;
      return result;
    } else {
      return 0;
    }
  }

  const getAllHproducts = () => {
    //it should taken in user. interested items

    //setLoadingStatus(true);

    const postemail = props.authState.user.useremail;

    axios
      .post(`${BASE_URL}/users/pricealertperuser`, { email: postemail }) //Query
      .then((res) => {
        const myproduct = res.data.data.mproduct;

        console.log(myproduct);
        getMHproducts(myproduct);
      })
      .catch((error) => console.error(`Error:${error}`));

    //setLoadingStatus(false);
  };
  useEffect(() => {
    getAllHproducts();
  }, []);

  useEffect(() => {
    // This effect runs whenever MHproducts changes
    // Perform other side effects or actions here
    setTimeout(() => setLoadingdel(false), 5000);
  }, [MHproducts]);
  useEffect(() => {
    // This effect will run whenever productChange changes
    // You can update the Monitor component's state or fetch new data here
    getAllHproducts();
    console.log("Monitor has re-rendered due to product change");
  }, [props.productChange]);

  const handleClick = (index) => {
    setClickedItems((prevClickedItems) => [...prevClickedItems, index]);
  };

  const hotsellingproductlistz = MHproducts.map((item, index) => (
    <tr
      key={index}
      style={clickItems.includes(index) ? { display: "none" } : {}}
    >
      <th>
        <div className="img-container">
          <img alt="Red dot" src={item.image} />
        </div>
      </th>
      <td>
        <div style={{ width: "500px" }}>
          <small>{item.product2monitor}</small>
        </div>
      </td>

      <td className="text-left">
        <div style={{ width: "100px" }}>
          <small> {item.product2monitorPrice}</small>
        </div>
      </td>

      <td className="text-left">
        <div style={{ width: "100px" }}>
          <small>
            {" "}
            {
              item.product2monitorPriceHistory[
                item.product2monitorPriceHistory.length - 1
              ]
            }
          </small>
        </div>
      </td>

      <td className="text-left">
        <small>
          {" "}
          {savings(
            item.product2monitorPriceHistory[
              item.product2monitorPriceHistory.length - 1
            ],
            item.product2monitorPriceHistory[
              item.product2monitorPriceHistory.length - 2
            ]
          )}
        </small>
      </td>
      <td className="text-left" style={{ paddingRight: "20px" }}>
        <div style={{ width: "100px", whiteSpace: "nowrap" }}>
          <Button
            onClick={() => {
              setbigChartData(item);

              //console.log("listen" + item.product2monitorPriceHistory);
            }}
          >
            {" "}
            Price History
          </Button>
        </div>
      </td>
      <td className="text-left">
        <div style={{ width: "100px", whiteSpace: "nowrap" }}>
          <OrderModal name={item.url2track} />
        </div>
      </td>

      <td className="td-number">
        <Button
          className="btn-link"
          color="primary"
          data-placement="top"
          id={`tooltip${index}`}
          title=""
          type="button"
          data={item}
          onClick={() => {
            const email = props.authState.user.useremail;
            const itemname = item.product2monitor;

            props.handledellist(email, itemname);
            handleClick(index);
          }}
        >
          {Loadingdel && <i className="fa fa-refresh fa-spin" />}

          {!Loadingdel && <i className="tim-icons icon-simple-remove" />}
        </Button>
        <UncontrolledTooltip
          delay={0}
          placement="top"
          target={`tooltip${index}`}
        >
          Remove item
        </UncontrolledTooltip>
      </td>
    </tr>
  ));

  return isLoading ? (
    <h4>
      {" "}
      <i className="fa fa-refresh fa-spin" /> Please wait..loading
    </h4>
  ) : (
    <>
      <Row></Row>
      <Card style={{ marginTop: "30px" }}>
        <Graph data={bigChartData} />
        <CardHeader>
          <CardTitle tag="h5">
            {" "}
            <h1> Product Watchlist</h1>
            <Button
              className="btn-round btn-just-icon"
              color="secondary"
              onClick={() => {
                getAllHproducts();
              }}
            >
              <Link>Refresh</Link>
            </Button>
          </CardTitle>
        </CardHeader>

        <CardBody>
          <div
            className="table-responsive"
            style={{
              overflow: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Table className="table-shopping" sx={{ minWidth: 1500 }}>
              <thead>
                <tr>
                  <th className="text-left">Product Image</th>
                  <th className="text-left">Product name</th>

                  <th className="text-left"> Last Price</th>
                  <th className="text-left"> Current Price</th>
                  <th className="text-left"> Savings</th>
                </tr>
              </thead>
              <tbody>{hotsellingproductlistz}</tbody>
            </Table>
          </div>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handledellist: (email, itemname) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch

      dispatch(monitordelprod(email, itemname));
    },
    addmonitored: (email, itemname) => {
      dispatch(addmonitoredprod(email, itemname));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Monitorproduct);
//export default Hotselling;
