/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import Dashboard from "views/Dashboard.js";




import Pricing from "views/pages/Pricing.js";
import Register from "views/pages/Register.js";
import Reset from "views/pages/reset.js";
import Resetpassword from "views/pages/resetpassword.js";
import Productpage from "views/pages/Productpage.js";
import User from "views/pages/User.js";
import Login from "views/pages/Login.js";

import HotsellingwithFB from "views/forms/HtsellingwithFb";


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/HotSellingwithfb",
    name: "Explorer",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-notes",
    component: HotsellingwithFB,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-image-02",
    component: User,
    layout: "/admin",
  },
  {
    path: "/Pricing",
    name: "Pricing",
    rtlName: "عالتسعير",
   // mini: "P",
   // rtlMini: "ع",
    icon: "tim-icons icon-image-02",
    component: Pricing,
    layout: "/admin",
  },

 
  {
    collapse: true,
    name: "More Info",
    rtlName: "صفحات",
    icon: "tim-icons icon-settings",
    state: "pagesCollapse",
    views: [
   
      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/reset",
        name: "reset",
        rtlName: "لوحة القيادة",
        imini: "R",
        rtlMini: "صع",
        component: Reset,
        layout: "/auth",
      }
      ,{
        path: "/resetpassword",
        name: "resetpassword",
        rtlName: "لوحة القيادة",
        imini: "R",
        rtlMini: "صع",
        component: Resetpassword,
        layout: "/auth",
      },
      {
        path: "/Productpage",
        name: "Productpage",
        rtlName: "لوحة القيادة",
        imini: "R",
        rtlMini: "صع",
        component: Productpage,
        layout: "/admin",
      }
    
    ],
  },
  
  
];

export default routes;
