import React from "react";
// react plugin used to create DropdownMenu for selecting items
import axios from "axios";
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import ClipLoader from "react-spinners/ClipLoader";
import "react-autocomplete-input/dist/bundle.css";
import { connect } from "react-redux";
import addStatCardAction from "../../actions/addStatCardAction";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
} from "reactstrap";
//import addStatCardAction from "actions/addStatCardAction";

const useStyles = makeStyles(() => ({
  inputColor: {
    color: "black",
  },
  option: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "grey !important",
      color: "Black !important",
      padding: "5px",
      margin: "2.5px",
    },
  },
}));

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const AddProduct = (props) => {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [state, setState] = useState({
    url: "",
    productImageURL: "",
    productPrice: "",
    productName: "",
  });
  const [searchName, setSearchName] = React.useState("");

  const handleOnChange = (e, value) => {
    //console.log("We are taking over")
    e.preventDefault();

    if (value !== null) {
      setSearchName(value.title);
    } else {
    }
  };

  function categorize(url) {
    if (url.toLowerCase().includes("jumia")) {
      return "jumia";
    } else if (url.toLowerCase().includes("alibaba")) {
      return "alibaba";
    } else if (url.toLowerCase().includes("shein")) {
      return "shein";
    } else if (url.toLowerCase().includes("hm")) {
      return "hm";
    } else if (url.toLowerCase().includes("amazon")) {
      return "amazon";
    } else if (url.toLowerCase().includes("asos")) {
      return "asos";
    } else if (url.toLowerCase().includes("farfetch")) {
      return "farfetch";
    } else if (url.toLowerCase().includes("konga")) {
      return "konga";
    } else if (url.toLowerCase().includes("aliexpress")) {
      return "aliexpress";
    } else if (url.toLowerCase().includes("jiji")) {
      return "jiji";
    }
  }

  async function priceFinalAlert(data) {
    try {
      const response = await axios.post(
        "https://app.salesngine.com/api/users/pricedropalert",
        data
      );
      if (response && response.status >= 200 && response.status < 300) {
        props.onProductChange();
        alert("Added");
      } else {
        alert("Network busy, Retry Now");
      }
    } catch (error) {
      alert("Network busy, Retry Now");
    }
  }

  async function priceAlert(url) {
    setLoading(true);
    const requestUrl = url;
    const vendor = categorize(url);
    // console.log(vendor);
    // console.log(url);
    try {
      const response = await axios.post(
        `https://sgservices.cfd/api/v1.0/site/${vendor}`,
        { url: url, username: "qwerty" }
      );

      if (response) {
        const responseString = JSON.stringify(response);

        setState({
          url: response.data.data.url,
          productImageURL: response.data.data.image,
          productPrice: response.data.data.price,
          productName: response.data.data.name,
        });
        console.log(`hitt`);
        //setLoading(false);
        await priceFinalAlert({
          email: props.email,
          product2monitor: state.productName,
          product2monitorPrice: state.productPrice,
          site: state.url,
          imageUrl: state.productImageURL,
          productasin: "ll",
          notification: "",
        });
      } else {
        alert("Try again");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert(
        "An error occurred while processing your request. Please try again later."
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function isValidHttpUrl(string) {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Input Product URL Below</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Col xs="12">
                      <input
                        className="form-control"
                        placeholder="https://www.asos.com/threadbare/threadbare-super-stretch-notch-front-bodysuit-in-clay/prd/202048043?clr=neutral&colourWayId=202048051&cid=4167"
                        onFocus={(e) => setState({ ...state, urlFocus: true })}
                        onBlur={(e) => setState({ ...state, urlFocus: false })}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value !== null) {
                            setState({ ...state, url: value });
                          }
                        }}
                        value={state.url}
                        style={{
                          border: isValid ? "1px solid red" : "",
                          width: "80%", // Specify the width you want
                          height: "70px",
                        }}
                      />
                    </Col>
                    <Col lg="5" md="12" sm="12">
                      <Button
                        color="primary"
                        style={{ marginTop: "20px" }}
                        className={
                          loading
                            ? "btn btn-green-full disabled"
                            : "btn btn-green-full text-heading-6"
                        }
                        onClick={() => {
                          if (state.url) {
                            if (isValidHttpUrl(state.url)) {
                              setIsValid(false);
                              priceAlert(state.url);
                            } else {
                              setIsValid(true);
                            }
                          }
                        }}
                      >
                        {loading ? (
                          <div
                            style={{
                              width: "250px",
                              paddingRight: "50px",
                              display: "block",
                            }}
                          >
                            <ClipLoader color="#52bfd9" size={20} /> Loading
                          </div>
                        ) : (
                          "Add to watchlist"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="12"></Col>
        </Row>
      </div>
    </>
  );
};

export default AddProduct;
