/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useEffect } from "react";
import Monitor from "views/forms/Monitor.js";
import Hotselling from "views/forms/Hotselling.js";
import { Redirect } from "react-router-dom";
//import googleTrends from 'google-trends-api';

import { connect } from "react-redux";
import AddProduct from "./forms/AddProduct";

//const googleTrends = require('google-trends-api');

// reactstrap components
import { Row, Col } from "reactstrap";

const Dashboard = (props) => {
  const [productChange, setProductChange] = useState(0);

  const handleProductChange = () => {
    setProductChange(productChange + 1); // Increment when change occurs
  };

  // useEffect(() => {
  //   // This effect will run whenever productChange changes
  //   // You can add any additional logic you need here
  //   console.log("AddProduct has changed");
  // }, [productChange]);
  // if (props.authState.user.subscribestate === false) {
  //   return <Redirect to="/admin/Pricing" />;
  // } else {
  return (
    <>
      <div className="content" style={{ marginTop: "30px" }}>
        <Row>
          <Col lg="12">
            <AddProduct
              email={props.authState.user.useremail}
              onProductChange={handleProductChange}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Monitor productChange={productChange} />
          </Col>
        </Row>
        {/* <Row>
            <Col lg="12">
              <Hotselling />
            </Col>
          </Row> */}
      </div>
    </>
  );
};
// };

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

//export default Dashboard
