import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow that just works",
    paragraph:
      "Salesengine simplifies the ecommerce journey, making it easy to start an online business, scale, and succeed. From dropshipping to selling your own products, we make selling online easy.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Understand the market forces that influence your sales.
                </div>
                <h3 className="mt-0 mb-12">TRACK YOUR MARKET POSITION</h3>
                <p className="m-0">
                  Use Salesngine product analytics to your products ranking in
                  the market. Discover how your pricing ranks compared to other
                  online businesses
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {
                  //  <Image
                  //  src={
                  //  require("./../../assets/images/features-split-image-01.png")
                  //  .default
                  //  }
                  //   alt="Features split 01"
                  //   width={528}
                  //   height={396}
                  // />
                }
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Research products
                </div>
                <h3 className="mt-0 mb-12">UNDERSTAND YOUR COMPETITORS</h3>
                <p className="m-0">
                  Top online businesses use Salesngine's pricing data and
                  insights to make smart decisions that their increase revenues
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {
                  //<Image
                  //src={require('./../../assets/images/features-split-image-02.png').default}
                  //alt="Features split 02"
                  //width={528}
                  //height={396} />
                }
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Datadriven Decision Making
                </div>
                <h3 className="mt-0 mb-12">
                  DROPSHIP OR ORDER AT CHEAPEST PRICES ONLINES
                </h3>
                <p className="m-0">
                  Salesngine will help you find out monthly sales of the
                  particular product and way more...!
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              ></div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Choosing the right products will determine the success of your
                  store.
                </div>
                <h3 className="mt-0 mb-12">SELECT HOTSELLING PRODUCT</h3>
                <p className="m-0">
                  Use Salesngine product Analytics to find winning products and
                  get cheapest price!
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {
                  //  <Image
                  //  src={
                  //  require("./../../assets/images/features-split-image-01.png")
                  //  .default
                  //  }
                  //   alt="Features split 01"
                  //   width={528}
                  //   height={396}
                  // />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
