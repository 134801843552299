import axios from "axios";
import { BASE_URL } from "../utils/config";

const registerAction =
  (category, name, email, password, password2) => (dispatch) => {
    //alert(password)
    const post2Data = {
      category,
      name,
      email,
      password,
      password2,
    };

    axios
      .post(`${BASE_URL}/users/register`, post2Data)
      .then((res) => {
        // Set current user
        dispatch({
          type: "register",
          payload: "true",
          userinfo: {
            username: post2Data.name,
            useremail: post2Data.email,
            subscribestate: res.data.subscribed,
            dateOfRegistration: res.data.date,
            stat: false,
          },
        });
      })
      .catch(function (error) {
        alert(console.error);
      });
  };

//at the moment, since we havent yet connected to the database we are going to say that each time we try to register we should not be able to register
export default registerAction;
