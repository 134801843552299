import React from "react";

import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
} from "reactstrap";

import { useEffect, useState } from "react";
import add2list from "actions/add2list";
import { connect } from "react-redux";
import productPageAction from "../../actions/productPageAction";
//import {Link} from 'react-dom'
import { Link } from "react-router-dom";

import axios from "axios";
import { BASE_URL } from "../../utils/config";

const Paginationer = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [categorys, setCategoryName] = useState(" ");
  const [productsToRender, setProductsToRender] = useState([]);
  const [isLoading, setLoadingStatus] = useState(false);

  //for the sweet alert
  const [alert, setAlert] = React.useState(null);
  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        className="mb-3"
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Added To Dashboard!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
        md="4"
      >
        <Button>
          <Link color="primary" className="mb-3" to="/admin/dashboard">
            Click Now To Go To Dashboard Now
          </Link>
        </Button>
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const getAllHproducts = (currentPage, categoryName) => {
    // start the loader
    setLoadingStatus(true);

    axios
      .get(
        `${BASE_URL}/products/hotsellingproducts?page=${currentPage}&categoryName=${categoryName}`
      ) //Query
      .then((res) => {
        const allHproducts = res.data.data.Hproduct;

        setProductsToRender(allHproducts);
        setLoadingStatus(false);
      })
      .catch((error) => {
        console.error(`Error:${error}`);
        setLoadingStatus(false);
      });
  };

  useEffect(() => {
    getAllHproducts(currentPage, categorys);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getAllHproducts(currentPage, categorys);
  }, [categorys]);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);

    setCurrentPage(pageNumber);
  }

  function removeFilter() {
    setCategoryName(" ");
  }

  const hotProducts = productsToRender.map((item, index) => (
    <Col lg="3" md="6" xs="12" key={index}>
      <Card
        className="card-stats"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardBody>
          <Row>
            <Col>
              <div className="photo">
                <img alt="Red dot" src={item.imgsrc} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="secondary">
                <small>
                  {" "}
                  <p>Category = {item.category}</p>{" "}
                </small>
                <small>
                  {" "}
                  <p>Product Name = {item.name.slice(0, 20).concat("...")}</p>
                </small>
                <small>
                  {" "}
                  <p>Cost price = N{item.CostPriceNaira}</p>
                </small>
                <small>
                  {" "}
                  <p>
                    Selling price = N{item.LocallowestSellingPrice} - N
                    {item.LocalhighestSellingPrice}
                  </p>
                </small>
                <small>
                  {" "}
                  <p>
                    Estimated Profit = N
                    {item.EstimatedProfitwithlowestsellingprice} - N
                    {item.EstimatedProfitwithhighestsellingprice}{" "}
                  </p>
                </small>
              </div>
            </Col>
          </Row>
        </CardBody>
        {/* <CardFooter>
          <div>
            <Link to="/admin/productpage" color="primary" className="mb-3">
              {" "}
              <Button
                block
                className="mb-3"
                color="primary"
                type="button"
                onClick={() => {
                  props.productPageacct(item.name);
                }}
                size="md"
              >
                {" "}
                Product research{" "}
              </Button>{" "}
            </Link>

            <Button
              block
              className="mb-3"
              color="primary"
              type="button"
              size="md"
              data={item}
              onClick={() => {
                successAlert();

                const email = props.authState.user.useremail;

                props.handleadd2list(email, item);
              }}
            >
              Add to my dashboard
            </Button>
          </div>
        </CardFooter> */}
      </Card>
    </Col>
  ));

  return isLoading ? (
    <h4>
      {" "}
      <i className="fa fa-refresh fa-spin" /> Please wait..loading
    </h4>
  ) : (
    <div className="content">
      

      <div className="row">{hotProducts}</div>

      <Row>
        <Col md="12">
          {alert}
          <CardHeader>
            <CardTitle tag="h4">Pages</CardTitle>
          </CardHeader>
          <CardBody>
            <nav aria-label="Page navigation example">
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    aria-label="Previous"
                    href="#pablo"
                    onClick={goToPreviousPage}
                    className={`prev ${currentPage === 1 ? "disabled" : ""}`}
                  >
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="tim-icons icon-double-left"
                      />
                    </span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={`paginationItem ${
                    currentPage === 1 ? "active" : null
                  }`}
                >
                  <PaginationLink onClick={changePage}>1</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={`paginationItem ${
                    currentPage === 2 ? "active" : null
                  }`}
                >
                  <PaginationLink onClick={changePage}>2</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={`paginationItem ${
                    currentPage === 3 ? "active" : null
                  }`}
                >
                  <PaginationLink onClick={changePage}>3</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={`paginationItem ${
                    currentPage === 4 ? "active" : null
                  }`}
                >
                  <PaginationLink onClick={changePage}>4</PaginationLink>
                </PaginationItem>

                <PaginationItem
                  className={`paginationItem ${
                    currentPage > 4 ? "active" : null
                  }`}
                >
                  <PaginationLink aria-label="Next" onClick={goToNextPage}>
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="tim-icons icon-double-right"
                      />
                    </span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>

            <Button>
              {" "}
              <a
                href="https://salesngine.com/contact/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Special product research
              </a>{" "}
            </Button>
            <p className="text-on-front">
              Havent found a product you are interested in? Click above to
              request for a particular product to be researched and results
              displayed
            </p>
          </CardBody>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleadd2list: (email, product2add) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch
      dispatch(add2list(email, product2add));
    },
    productPageacct: (name) => {
      dispatch(productPageAction(name));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Paginationer);
