//import Modal from 'react-bootstrap/Modal';

import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { useState } from "react";

function ModalExample(data) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a variant="secondary" href="#pablo" size="md" onClick={handleShow}>
        Product Reviews
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p> 1. {data.data.Reviews[0]}</p>
            <p> 2. {data.data.Reviews[1]}</p>
            <p> 3. {data.data.Reviews[2]}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalExample;
