import axios from 'axios'

import { BASE_URL } from '../utils/config';


const delprod = (
    
    email,
    itemname
) => dispatch =>{

    //alert(password)
    
        
       const postemail=  email
        const producttodel = itemname
        
    
    axios.post(`${BASE_URL}/users/deleteproduct`, {email: postemail,product2del:producttodel })
     .then(res=>{
        // Set current user
        dispatch({
          type:"delproduct",
          payload:"true",
          iproductlist:{
            useremail: email,
            userproductlist : res.interestedproducts
          }
        });
        //alert("Succesfully deleted from your dashboard");
     })
     .catch(res => alert(res))
    }




export default delprod;


// Login - get user token
