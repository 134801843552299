/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import registerAction from "../../actions/registerAction";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { useHistory } from "react-router-dom";

import { useState } from "react";
import validator from "validator";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Register = (props) => {
  const [state, setState] = React.useState({
    category: "NA",
    name: "",
    email: "",
    password: "",
    password2: "",
    acct: 1,
  });

  const addtoEmaillist = (email, first_name) => {
    const postDatal = {
      email,
      first_name,
    };

    axios
      .post(`${BASE_URL}/users/emaillist`, postDatal)
      .then((res) => {})
      .catch(console.error());
  };

  const [emailError, setEmailError] = useState("");
  //const validateEmail = (e) => {
  //var email = e.target.value;

  // if (validator.isEmail(email)) {
  // setEmailError("Valid Email :)");
  // } else {
  // setEmailError("Enter valid Email!");
  // }
  //};
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });
  return (
    <>
      <div className="content">
        <Container>
          <Row style={{ marginTop: "10%" }}>
            <Col className="ml-auto mr-auto" md="7">
              <Card className="card-register card-white">
                <CardHeader>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <CardTitle tag="h4">Register</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form">
                    {/* <InputGroup
                      className={classnames({
                        "input-group-focus": state.categoryFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-bag-16" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="What do you sell ?"
                        type="text"
                        onFocus={(e) =>
                          setState({ ...state, categoryFocus: true })
                        }
                        onBlur={(e) =>
                          setState({ ...state, categoryFocus: false })
                        }
                        onChange={(e) =>
                          setState({ ...state, category: e.target.value })
                        }
                        value={state.category}
                      />
                    </InputGroup> */}
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.nameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Full Name"
                        type="text"
                        onFocus={(e) => setState({ ...state, nameFocus: true })}
                        onBlur={(e) => setState({ ...state, nameFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, name: e.target.value })
                        }
                        value={state.name}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onFocus={(e) =>
                          setState({ ...state, emailFocus: true })
                        }
                        onBlur={(e) =>
                          setState({ ...state, emailFocus: false })
                        }
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                        value={state.email}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.passFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, password: e.target.value })
                        }
                        value={state.password}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": state.passFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        onFocus={(e) => setState({ ...state, passFocus: true })}
                        onBlur={(e) => setState({ ...state, passFocus: false })}
                        onChange={(e) =>
                          setState({ ...state, password2: e.target.value })
                        }
                        value={state.password2}
                      />
                    </InputGroup>

                    <FormGroup check className="text-left">
                      <Label check>
                        <Input type="checkbox" />
                        <span className="form-check-sign" />I agree to the{" "}
                        <a
                          href="https://salesngine.com/privacy-policy/"
                          className="button-link"
                        >
                          terms and conditions
                        </a>
                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="secondary"
                    href="#pablo"
                    size="lg"
                    onClick={() => {
                      setLoading(true);
                      if (state.email && state.name && state.category) {
                        if (validator.isEmail(state.email)) {
                          props.handleRegister(
                            state.category,
                            state.name,
                            state.email,
                            state.password,
                            state.password2
                          );
                          addtoEmaillist(state.email, state.name);

                          setTimeout(() => {
                            setLoading(false);
                            history.push("/auth/dashboard");
                          }, 2000);

                          //window.location = "https://salesngine.com/";
                        } else {
                          setLoading(false);
                          setEmailError(" Complete the form!");
                        }
                      } else {
                        setEmailError(" Complete the form!");
                      }
                    }}
                    //onSubmit= {submitHandler}
                  >
                    {loading && (
                      <i
                        className="fas fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    Get Started
                  </Button>
                  <span
                    style={{
                      align: "center",
                      fontWeight: "small",
                      color: "Red",
                    }}
                  >
                    {emailError}
                  </span>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleRegister: (category, name, email, password, password2) => {
      //basically dispatch returns handlelogin that takes arguements and returns a dispatch
      dispatch(registerAction(category, name, email, password, password2));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register); //at
