/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
//import Footer from "components/Footer/Footer.js";
import FooterNav from "views/components/FooterNav.js";

import routes from "routes.js";

const Pages = (props) => {
  React.useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "DefaultS Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getFullPageName = (routes) => {
    let pageName = getActiveRoute(routes);
    console.log(pageName);
    switch (pageName) {
      // case "Pricing":
      // return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Lock Screen":
        return "login-page";
      case "Resetpassword":
        return "reset-page";
      default:
        return "Default Brand Text";
    }
  };
  if (props.authState.loggedIn) {
    //if subscribed then the user should move to dashboard. I think the login shold include subscribed. Basically if subscribed should be there. If logged in and subscribed -> dashboard, if logged in but not subscribed -> pricing, if not logged in and not subscribed --> register

    return <Redirect to="/admin/HotSellingwithfb" />;
    // <Redirect to="/admin/dashboard" />;
  } else {
    return (
      <>
        <AuthNavbar brandText={getActiveRoute(routes) + " Page"} />
        <div className="wrapper wrapper-full-page">
          <div className={"full-page " + getFullPageName(routes)}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
            <FooterNav fluid />
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Pages);
