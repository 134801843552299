/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import {connect} from "react-redux";
import loginAction from "../../actions/loginAction";
import { Link } from 'react-router-dom';


// reactstrap components
import {
  
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";


const Login = (props) => { //i can include the login directly using{} but with this i am calling from store directly
  const [state, setState] = React.useState({
    name:"",
    email:"",
    password:"",
    
  });
  const [loading, setLoading] = React.useState(false);
   
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  
  });
  
  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <CardTitle tag="h1">Log in</CardTitle>
                </CardHeader>
                <CardBody>

                  
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="Email"
                      onFocus={(e) => setState({ ...state, emailFocus: true })}
                      onBlur={(e) => setState({ ...state, emailFocus: false })}
                      onChange={(e) => setState({ ...state, email: e.target.value })}
                      value={state.email}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                      onChange={(e) => setState({ ...state, password: e.target.value })}
                      value={state.password}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="primary"
                    href="#pablo"
                    disabled={loading}
                    size="lg"
                    onClick={()=>{
                      if(state.email && state.password ){
                    setLoading(true);
                      props.handleLogin(
                        state.email,
                        state.password
                      );
                     ;
                     setTimeout(() => {
                      setLoading(false);
                    }, 3000);
                      }
                    }}
                  >
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                       // style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading</span>}
                    {!loading && <span>Sign in</span>}
                  </Button>
                  <div className="pull-left">
                    <h6>

              
                 
                 <Link to="/auth/register" className="button-link" >Register</Link> 
              
              
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                
                      <Link to="/auth/reset" className="button-link" > Forgot password? </Link> 
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};
//Basically the ...state means global state. We can access the state by using state.Authstat.email
const mapStateToProps = 
state=> ({
  ...state
});

const mapDispatchToProps = 
dispatch => {
  return{
    handleLogin: (email, password) =>{ //basically dispatch returns handlelogin that takes arguements and returns a dispatch 
      dispatch(loginAction(email, password))
      
    }
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login); //at this point states and dispatches are connected to login via the props that contains an object with keys and values. 