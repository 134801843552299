/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

import axios from "axios";

import { BASE_URL } from "../../utils/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

const Resetpassword = (props) => {
  //i can include the login directly using{} but with this i am calling from store directly
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);

  const resetemail = (existingemail, newpassword) => {
    axios
      .post(`${BASE_URL}/users/resetpassword`, {
        email: existingemail,
        password: newpassword,
      })
      .then((res) => {
        // Set current user

        alert("Successfully reseted");
      })
      .catch((error) => console.error(`Error:${error}`));

    setLoading(false);
  };

  React.useEffect(() => {
    document.body.classList.toggle("resetpassword-page");
    return function cleanup() {
      document.body.classList.toggle("resetpassword-page");
    };
  });

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <CardTitle tag="h1">Reset</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      onFocus={(e) => setState({ ...state, emailFocus: true })}
                      onBlur={(e) => setState({ ...state, emailFocus: false })}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                      value={state.email}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      type="password"
                      onFocus={(e) => setState({ ...state, passFocus: true })}
                      onBlur={(e) => setState({ ...state, passFocus: false })}
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                      value={state.password}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="mb-3"
                    color="secondary"
                    href="#pablo"
                    disabled={loading}
                    size="lg"
                    onClick={() => {
                      setLoading(true);
                      resetemail(state.email, state.password);
                    }}
                  >
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        // style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading</span>}
                    {!loading && <span>Reset now</span>}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Resetpassword; //at this point states and dispatches are connected to login via the props that contains an object with keys and values.
