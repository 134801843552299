import axios from "axios";
import { BASE_URL } from "../utils/config";
const add2list = (email, product2addn) => (dispatch) => {
  //alert(password)

  const postemail = email;
  const producttoadd = product2addn;

  axios
    .post(`${BASE_URL}/users/addproduct`, {
      email: postemail,
      product2add: producttoadd,
    })
    .then((res) => {
      // Set current user
      console.log("add2list action   " + JSON.stringify(res));

      dispatch({
        type: "addproduct",
        payload: "true",
        userinfo: {
          username: res.data.name,
          useremail: email,
          userproductlist: product2addn,
          subscribestate: res.data.subscribed,
          dateOfRegistration: res.data.date,
          stat: false,
        },
      });
    })
    .catch((res) => alert(res));
};
export default add2list;
